import React, { useState } from "react"
import { Link } from "gatsby"
import Dropdown from "../components/Dropdown"
import Root from "../components/root"
import LogoImage from "../gatsbyImages/LogoImage"
import classes from "./categorization.module.css"
import Layout from "../components/layout"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const CategorizationPage = () => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false)

  return (
    <Layout>
      {isDropdownOpen && <Dropdown home={true} />}
      {!isDropdownOpen && (
        <div
          className={classes.logo}
          onClick={e => setisDropdownOpen(!isDropdownOpen)}
        >
          <LogoImage />
        </div>
      )}

      <Root className={classes.root} onClick={e => setisDropdownOpen(false)}>
        <div className="max-w-xs xl:max-w-6xl mx-auto relative">
          <AnchorLink
            to="/video-editing#contactus"
            stripHash
            className={classes.professional}
          >
            <span className="font-thin ml-2">Filmmakers</span>
            <span className="font-thin ml-2">Videographers</span>
            <span className="font-thin ml-4">Content creators</span>
            <span className="font-thin ml-6">Cinematographers</span>
          </AnchorLink>

          <Link to="/questionnaire" className={classes.notProfessional}>
            <span className="font-thin leading-tight ml-2">I'm not a</span>
            <span className="font-thin leading-tight ml-4">professional</span>
          </Link>
        </div>
      </Root>
    </Layout>
  )
}

export default CategorizationPage
